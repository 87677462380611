/* @import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap"); */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Work Sans";
  font-smooth: inherit;
}

/* h2{
  font-size: 32px;
}
h3{
    font-size: 20px;
}*/
p {
  font-size: 16px;
  margin: 0px 0px 20px;
}

.left,
.right {
  float: left;
  width: 20%;
  /* The width is 20%, by default */
}

.main {
  float: left;
  width: 60%;
  /* The width is 60%, by default */
}

.contact {
  width: 100%;
}

.contact_details {
  padding-left: 2%;
  padding-top: 2%;
  padding-right: 5%;
  width: 50%;
}

.contact_details h2 {
  padding-bottom: 2%;
}

.contact_details h6 {
  font-size: 18px;

  padding-bottom: 2%;
}

.contact_details h3 {
  font-size: 25px;
  padding-bottom: 2%;
}

.contact_details h5 {
  font-size: 20px;

  padding-bottom: 2%;
}

.contact_form {
  padding-right: 15%;
  padding-left: 5%;
  padding-top: 2%;
  width: 50%;
}

.contact_form h2 {
  font-size: 40px;

  padding-bottom: 2%;
}

.form-group {
  font-size: 16px;

  padding-bottom: 2%;
}

.form-control {
  font-size: 16px;
  padding-bottom: 2%;
  height: 55px;
  color: #666;
  border: 1px solid #aca9a9;
  width: 70%;
  font-family: sans-serif;
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .left,
  .main,
  .right {
    width: 100%;
    /* The width is 100%, when the viewport is 800px or smaller */
  }
}

.emMob {
  color: royalblue;
  font-weight: 400;
}

.page-section {
  /* padding: 6rem 0; */
  padding: 0 0;
  margin-left: 2rem;
}

.page-section h2.section-heading,
.page-section .section-heading.h2 {
  font-size: 32px;
  color: #191919;
  /* font-size: 4.5rem; */
  margin-top: 5rem;

  margin-bottom: 1rem;
  padding-bottom: 3rem;
}

h6 {
  font-size: 2rem;
  padding-top: 0.5%;
  font-weight: 400;
  padding-bottom: 0.5%;
  color: rgb(94, 91, 91);
}

h3 {
  padding-top: 3.5%;
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 3.5%;
}

h5 {
  font-size: 2rem;
  color: rgb(25, 25, 25);
  padding-bottom: 3.5%;
}

label {
  display: inline-block;
  color: rgb(94, 91, 91);
  font-size: 16px;
}

.form-control {
  color: rgb(94, 91, 91);
}

.page-section h3.section-subheading,
.page-section .section-subheading.h3 {
  font-weight: 400;
  margin-bottom: 4rem;
}

/* .btn {
  margin: 6px;
  color: rgb(248, 240, 244);
  background-color:#df2176;
  border-radius: 90px;
  min-width: 200px;
  min-height: 60px;
  font-size: 20px;
  font-weight: 600;
} */

.btn:hover {
  background-color: #685de5;
  color: white;
}

section#contact {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
}

section#contact .section-heading {
  color: #0e0d0d;
}

section#contact form#contactForm .form-group {
  margin-bottom: 1.5rem;
}

section#contact form#contactForm .form-group input,
section#contact form#contactForm .form-group textarea {
  padding: 1.25rem;
}

section#contact form#contactForm .form-group input.form-control {
  height: auto;
}

section#contact form#contactForm .form-group-textarea {
  height: 100%;
}

section#contact form#contactForm .form-group-textarea textarea {
  height: 100%;
  min-height: 10rem;
}

section#contact form#contactForm p.help-block {
  margin: 0;
}

section#contact form#contactForm .form-control:focus {
  border-color: black;
  box-shadow: none;
}

section#contact form#contactForm ::-webkit-input-placeholder {
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm :-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm ::-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm :-ms-input-placeholder {
  font-weight: 700;
  color: #ced4da;
}

@media screen and (min-width: 80rem) {
  .contact {
    margin: 8em 12em;
  }
}

.input-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}


.input {
  color: #666;
  font-size: 16px;
  border: 1px solid #aca9a9;
  border-radius: 0;
  padding: 15px 20px;
  max-width: 70%;
}

.textarea {
  color: #666;
  font-size: 16px;
  border: 1px solid #aca9a9;
  border-radius: 0;
  padding: 15px 20px;
  /* <<<<<<< HEAD */
  width: 70%;
  /* } */
  /* ======= */
  max-width: 70%;
}

/* >>>>>>> 05b4dc3e3f26fa1c46c566664e696258a9897946 */
.input {
  color: #666;
  font-size: 16px;
  border: 1px solid #aca9a9;
  border-radius: 0;
  padding: 15px 20px;
  max-width: 70%;
}

.labelReq {
  font-size: 16px;
  margin: 0px 0px 8px;
  /* <<<<<<< HEAD */
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  color: #666;
  font-size: 16px;
  /* ======= */

  /* >>>>>>> 05b4dc3e3f26fa1c46c566664e696258a9897946 */
}

.text-area {
  color: rgb(89, 89, 89);
  font-size: 16px;
  box-sizing: border-box;
}

.contact-pera {
  box-sizing: border-box;
  color: rgb(89, 89, 89);
  font-size: 16px;
  line-height: 24px;
}

.section-heading {
  color: rgb(14, 13, 13);
  font-family: "Work Sans";
}

.location-name {
  color: rgb(89, 89, 89);
  font-size: 22px;
  line-height: 33px;
  font-family: "Work Sans";
}

/* spinner */
.react-spinner {

  padding-left: 50%;
}

@media screen and (max-width: 500px) {
  .btn:hover {
    background-color: #685de5;
    color: white;
  }

}

@media(max-width: 767px) {
  p {
    font-size: 14px;

  }
}

/* Add this in your 'contact.css' file or in a style block within 'Contacts' component file */

/* Styles for mobile responsiveness */
@media only screen and (max-width: 767px) {
  .input-field-container {
    display: flex;
    flex-direction: column;
  }

  .input,
  .textarea {
    width: 100%;
    max-width: 93%;
    box-sizing: border-box;
  }

  .phone-input-container {
    width: 100% !important;
    max-width: 93% !important;
    box-sizing: border-box;
  }
  .contactButton{
    min-width: 150px;
    min-height: 50px;
    font-size: 22px;
    font-weight: 600;
  }
  .select-control{
    width: 100% !important;
    max-width: 93% !important;
    box-sizing: border-box;
  }
}

/* PhoneInput styles for larger screens (website) */
.phone-input-container {
  width: 70%;
  /* Adjusted to 70% for larger screens */
  border-color: aca9a9 !important;
  font-size: 18px;
  padding-bottom: 10px;
}

.phone-input {
  color: #666;
  font-size: 20px;
  border: 1px solid aca9a9 !important;
  border-radius: 0;
  padding: 15px 20px;
  max-width: 70%;
  /* Adjusted to 70% for larger screens */
}
.select-control{
  color: #666;
  font-size: 16px;
  border: 1px solid #aca9a9;
  border-radius: 0;
  padding: 15px 20px;
  width: 70%;
  max-width: 70%;
}

/* button */
.contactButton {
  margin: 6px;
  background-color: #df2176;
  color: rgb(248, 240, 244);
  border: 1px solid var(--primary);
  border-radius: 90px;
  min-width: 150px;
  min-height: 50px;
  font-size: 22px;
  font-weight: 600;
}
