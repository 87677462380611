@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@900&family=Work+Sans&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Work Sans', sans-serif; */

}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.page-section {
  padding: 3rem 0;
  background-color: #f2f2f2;


}


.page-section h2.section-heading-card,
.page-section .section-heading-card.h2 {
  font-size: 32px;
  margin-top: 2%;
  color: #151515;
}

img,
svg {
  vertical-align: middle;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em;
}

.svg-inline--fa.fa-w-20 {
  width: 1.25em;
}

/* .card {
  border: 0.5px solid #ccc;
  border-radius: 10px;
  padding: 1.5rem;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 5px;
  background-color: #ffffff;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

} */

.cardStyle{
  border-radius: 10px;
  padding: 1.5rem;
  text-align: left;
  width: 100%;
  margin: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  border: 1px solid #E4E4E4;
}
.cardStyle:hover{
border-radius: 10px;
  padding: 1.5rem;
  text-align: left;
  width: 100%;
  margin: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  box-shadow: 0 1.5rem 1.5rem #E4E4E4
}

@media only screen and (max-width: 480px){
  .card {
    gap: 1.5rem;
    margin-top: 0;
}
}


/* .card{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  text-align: center;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #07172d;
  box-shadow: 0 1.5rem 2.125rem #0303033d;
  z-index: 100;
} */

.page-section h3.section-subheading,
.page-section .section-subheading.h3 {
  font-size: 1.8rem;
  line-height: 1.75;
  font-weight: 400;
  text-align: center;
  word-spacing: -1px;
}

.cards_emp_card h4 {
  --bs-text-opacity: 1;
  font-size: 2rem;
  color: #151515 !important;
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

section {
  display: block;
}

.page-section {
  padding: 6rem 0;
}

/* element.style {
} */
.text-muted {
  --bs-text-opacity: 1;
  font-size: 1.7rem;
  color: #6c757d !important;
  text-align: justify;
  text-align: center;
  /* padding-bottom: 70px; */
  line-height: 1.5;
}

.my-3 {
  font-size: 1.8rem;
  color: #161717 !important;
  padding-top: 5%;
}

p {
  line-height: 1.75;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

user agent stylesheet p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}



.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc800;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #ffc800;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc800;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 255, 200, 0;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 200, 0;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Roboto Slab, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  /* Show All Properties (1 more) */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;
}

.h4,
h4 {
  font-size: calc(1.5rem + .3vw);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.text-center {
  text-align: center !important;
  font-weight: 400;
  font-size: 16px;
  /* margin-top: 40px; */
}

.serviceClassName {
  color: Black;
  line-height: 48px;
  font-size: 42px;
  font-weight: 600;
  margin-top: 5rem;
  /* padding-top: 3rem; */
}





/* 
h1 {
  text-align: center;
  padding-top: 3rem;
  font-family: sans-serif;


  font-size: 40px;
} */

.cards_emp_card {
  text-align: center;
  box-sizing: border-box;
  padding-top: 10%;
  padding-bottom: 7%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  color: #1d1d1e;
  font-weight: lighter;
  display: 'flex';
  flex-direction: column;
  align-items: center;
  justify-items: center;

}


.img-1 {
  max-width: 20px;

  height: 25px;

}


.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}


.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}


.column {
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
}

.element_img {
  padding-left: 43%;
  padding-bottom: 10%;
}


/*horizontal line*/
hr.new1 {
  border: 1.5px solid black;
  margin: auto;
  width: 70%;
}



.service-container{
overflow: hidden;
}

/* //clients */

.serviceList {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  text-align: center;
  padding: 1rem;


}

.achieve-container {
  box-sizing: border-box;
  padding: 2rem;
  padding: 0;
  margin-top: 5rem;

}

/* .page-section-services {
  padding: 70px 0px 100px 0px;
} */
.achieve {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;

}

.achive-icon {
  fill: #b39573;
  color: #b39573;
  border-color: #b39573;
  /* font-weight: 400; */
  display: inline-block;
  line-height: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  font-size: 50px;
  text-align: center;
}

.clients_name {
  color: #43403e;
  font-family: "Roboto", Sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 4px;
  padding-top: 10px;
  word-spacing: 9999999px;
  margin-left: 5rem;
  font-weight: 500;
}

/* font-family: "Vidaloka", Sans-serif;*/
.no_projects {
  padding-left: 10px;

  font-size: 60px;
  font-weight: 500;
  line-height: 0.4em;
  letter-spacing: 1.5px;
  font-family: 'Frank Ruhl Libre', serif;


}

.containerStyle {

  text-align: center;
  font-size: 32px;
  color: #191919;
  font-family: Work Sans;
  font-weight: 500;
}



@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width:1000px) {
  .serviceList {
    grid-template-columns: 1fr;
  }

  .achieve {

    grid-template-columns: 1fr;

  }


  .achive-icon {

    font-size: 100px;
    text-align: center;
    margin: 30px;

  }

  hr.new1 {
    border: 1.5px solid black;
    margin: auto;
    width: 50%;



  }

  .clients_name {
    word-spacing: normal;

  }
}

/*happy-client-section*/
@media only screen and (max-width: 600px) {
  .iconsNew {
    display: grid;
    grid-template-rows: 1fr;


  }

  .achive-icon {
    font-size: 50px;
    padding-bottom: 2rem;
  }

  .no_projects {
    font-size: 50px;
  }

  .clients_name {
    padding-top: 3rem;
    font-size: 10px;
    margin-right: 5rem;
  }

  .serviceList {
    transform: scale(0.9);
  }

  .btn {
    font-size: 15px;
    font-weight: 600;
  }

}