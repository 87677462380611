/* About.css */
/* new about us page */
.main-container{
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); 
  background: linear-gradient(to bottom, #f5f5f5, #ffffff);
}
.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.about_title {
  font-size: 3rem; 
  color: #150404;
  margin-bottom: 10px;
  font-weight: 500;
}
header {
  text-align: center;
  margin-bottom: 20px;
}

.about-us-content {
  display: flex;
  flex-direction: column;
  text-align: justify;
}
/* mission */
.mission-content{
  display: flex;
  flex-direction: column;
  text-align: justify;
  /* background: linear-gradient(to bottom, #f0f0f0, #fdfbfb); */
  /* margin: 30px; */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);  */

}
.mission-content-inner{
  text-align: justify;
  flex: 1;
  padding: 4rem;
  font-size: 16px;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
}
/* team */
.about-section-style {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  flex-wrap: wrap;
  gap: 20px;
}

.sectionCotainer{
  text-align: justify;
  flex: 1;
  padding: 4rem;
  font-size: 16px;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;

}
.cards-container {
  text-align: left;
  flex: 1;
  /* padding: 4rem; */
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Default styles for all screens */
.card {
  border-radius: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  padding: 1rem;
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out;
  
}
.card:hover {
  transform: translateY(-10px); /* Adjust the scale factor as needed */
}
/* .slide-in-from-top {
  animation: slideFromTop 0.5s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes slideFromTop {
  0% {
    transform: translateY(-300px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */
.zoom-in {
  animation: zoomIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: center center;
  opacity: 0;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .card {
    width: 100%;
    height: 50%;
  }
}


@media (min-width: 768px) {
  .about-us-content {
    flex-direction: row;
    justify-content: space-between;
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    width: calc(50% - 20px); 
}
}
/* Your existing styles for about-section-style */

.card-container-style {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}

.cardContentStyle {
  border-radius: 15px;
  flex: 1;
  padding: 20px;
  margin: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  text-align: center;
  /* transition: transform 0.3s ease-in-out, color 0.3s ease-in-out; */

}
/* .cardContentStyle:hover {
  transform: translateY(-10px); 
} */
.card-container-style :hover{
  background-color:#dee2e6;
  color:black;
}

.cardLeft {
  order: 1;
  background-color: white;
  color:black;
}

.cardRight {
  order: 2;
  background-color: white;
  color:black;
}

@media (max-width: 600px) {
  .card-container-style {
    flex-direction: column;
  }

  .cardLeft, .cardRight {
    order: 0; /* Reset the order for smaller screens */
  }
  .about_title {
    font-size: 2rem; 
    margin-bottom: 5px;
    font-weight: 600;
    text-align: center;
  }
  .about-section-style {
    flex-direction: column; 
    align-items: center;
  }
  .sectionCotainer{
    padding: 0;
  }
  .mission-content-inner{
    padding: 1rem;
  }
  .about_details{
    justify-content: center;
    text-align: justify;
  }
  .titleStyle{
    font-size: 2rem; 
    margin-bottom: 5px;
    font-weight: 600;
  }
}

