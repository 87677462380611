.form_fields[type="number"] {
  /* Standard property */
  appearance: textfield;

  /* WebKit browsers (Chrome, Safari) */
  -webkit-appearance: textfield;

  /* Mozilla Firefox */
  -moz-appearance: textfield;

  /* Microsoft Edge */
  -ms-appearance: textfield;

  /* Opera */
  -o-appearance: textfield;
}

.no-spinner[type="number"]::-webkit-inner-spin-button,
.no-spinner[type="number"]::-webkit-outer-spin-button {
  /* WebKit browsers (Chrome, Safari) */
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner[type="number"] {
  /* Standard property (non-standard for now) */
  appearance: textfield;
}


/* jobOpeningList */
.loader-container {
  width: 100%;
  padding: 6rem;
  box-sizing: border-box;
}

/* Responsive styles for desktop screens (greater than 768px) */
/* @media (min-width: 768px) {
  .responsive-container {
    width: 50%; 
    padding: 1rem;
  }
} */

/* Responsive styles for mobile screens (up to 767px) */
@media (max-width: 767px) {
  .responsive-container {
    width: 100%;
    padding: 1rem;
  }
}


/* Add responsive styles here as needed */

/* Default styles */
.dialogStyle {
  border: solid 1px hsla(0, 0%, 0%, 0.5);
  font-size: 18px;
  padding-left: 4rem;
  max-width: 100%;
}

/* Media query for screens smaller than 768px (e.g., smartphones) */
@media screen and (max-width: 768px) {
  .dialogStyle {
    font-size: 16px;
    padding-left: 2rem;
  }
}

/* Media query for screens larger than 768px (e.g., tablets and desktops) */
@media screen and (min-width: 769px) {
  .dialogStyle {
    font-size: 20px;
    padding-left: 6rem;
  }
}


.no-jobs {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #0d0d0d;
  border: 1px solid #ccc;
  background-color: #df2176;
  margin: 15rem auto;
  max-width: 300px;
  border-radius: 5px;
  /* height: 40px; */
}

.jobList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Create a 3-column grid */
  gap: 30px;
  text-align: center;
  padding: 1rem;
}

.cardStyleJob {
  border-radius: 10px;
  /* padding: 1.5rem; */
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  background-color: #ffffff;
}

/* Style for other job details */
.styleList {
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.5;
  font-size: 15px;
  color: black;
  font-weight: 500;
  display: flex;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.icon {
  font-size: 15px;
  width: 20px;
  margin-right: 10px
}

.text {
  margin-right: 10px;
  font-size: 15px;
}

.job-location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cardStyleJob h2 {
  text-align: center;
}

/* Base styles for larger screens */
.job-openings-container {
  max-width: 100%;
  width: 1280px;
  margin: 0 auto;
  padding-left: 6rem;
  padding-right: 6rem;
  text-align: justify;
  height: 100%;
  background: linear-gradient(to bottom, #f5f5f5, #ffffff);
}

.jobListTitle {
  font-size: 3rem;
  padding: 2rem;
  text-align: center;
  margin-left: 5rem;
  font-weight: 600;
}

/* Responsive styles for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .job-openings-container {
    max-width: 100%;
    padding: 2rem;
  }

  .jobList {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .jobListTitle {
    font-size: 2.5rem;
    padding: 1rem;
    margin-left: -1rem;
  }


}


.job-form {
  max-width: 1339px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
  height: 90vh;
}

.job-openings-list {
  list-style: none;
  padding: 0;
}

.job-openings-item {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.job-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: black;
  font-weight: 600;
  font-size: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.input-with-suffix {
  position: relative;
}

.input-with-suffix input {
  width: calc(100% - 40px);
  /* Adjust the width based on your design */
}

.suffix {
  font-weight: 500;
  font-size: 1.8rem;
  position: absolute;
  right: 50px;
  /* Adjust the right position based on your design */
  top: 50%;
  /* Center the suffix vertically */
  transform: translateY(-50%);
}



.view-job-button {
  color: #3618f7;
  /* border: none; */
  /* border-radius: 55px; */
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  text-decoration: none;
  text-align: left;
  margin-top: 8px;
  /* background: 'transparent'; */
  /* border: 'none'  */
}

.view-job-button:hover {
  color: #df2176;
}

/* jobOpeningsDetails */
/* Base styles */
/* Base styles for larger screens */
.jobTitle {
  font-size: 3rem;
  padding: 2rem;
  text-align: left;
  margin-left: 5rem;
  font-weight: 600;
}

.itemListData {
  font-size: 15px;
  text-align: justify;
  line-height: 1.5;
  font-family: sans-serif;
  padding-top: 2rem;
  margin-bottom: 8px;
}

.detailsCard {
  padding: 4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  max-width: 1280px;
  text-align: justify;
  flex: 2;
  font-size: 16px;
}

.viewBtn {
  background-color: #df2176;
  color: #f2f2f4;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  height: 45px;
  text-decoration: none;
  text-align: center;
  margin-top: 10px;
  border-radius: 5px;
}

.viewBtn:hover {
  background-color: #5c4fc5;
  color: white;
}

.textStyles {
  font-weight: bold;
  font-size: 16px;
}

.listText {
  font-size: 18px;
  font-weight: 600;
  text-align: justify;
}

.label {
  display: block;
  font-size: 18px;
}

.list-item {
  padding: 1rem;
}

.listApplyitem {
  display: flex;
}

.itemIcon {
  font-size: 30px;
  padding: 15px 15px 15px 15px;
}

.jobDetailscontainer {
  justify-content: center;
  text-align: center;
  padding: 1rem;
  max-width: 1280px;
  margin: 0 auto;
  /* background-color: #f9fafd; */
  background: linear-gradient(to bottom, #f5f5f5, #ffffff);
}

.applyDetailsCardStyle {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  flex-wrap: wrap;
  margin-left: 5rem;
  margin-right: 4rem;
  gap: 20px;
}

.applyCard {
  background-color: #ffffff;
  text-align: left;
  flex: 1;
  padding: 4rem;
  font-size: 16px;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
}

/* Responsive styles for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .jobTitle {
    font-size: 2rem;
    padding: 1rem;
    text-align: left;
    margin-left: 1rem;
    font-weight: 600;
  }

  .detailsCard {
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    max-width: 1280px;
    text-align: justify;
    flex: 2;
    font-size: 16px;
  }

  .applyDetailsCardStyle {
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }

  .applyCard {
    flex: 1;
    margin-top: 1rem;
    padding: 4rem;
  }

  .list-item {
    padding: 1rem;
  }
}




.error-capta {
  color: #ff0055;
  margin-top: 29px;
  font-size: 15px;
}

.capta-field {
  margin-left: 23px;
  margin-top: 24px;
}

/* @media screen and (max-width: 320px) {
  .job-openings-container {
    padding: 63px;
  }

  .apply-btn {
    width: 90px;
    height: 33px;
    font-size: 13px;
  }
} */

/*  job-form  */
/* .job-form-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: "100%";
} */

.job-form-card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 19px auto;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.error-field {
  margin-top: 5px;
  color: #ff0055;
  font-size: 15px;
}

.select-error {
  color: #ff0055;
  margin-top: 33px;
  margin-left: -8px;
}

.form-list {
  display: flex;
  flex-direction: row;
}

.form_input {
  color: #666;
  font-size: 20px;
  border: 1px solid #aca9a9;
  border-radius: 1rem;
  padding: 15px 20px;
  max-width: 70%;
  margin: 1.5rem;
  height: 5rem;
}

.form-btn:hover {
  background-color: #ff0055;
}

.form_label {
  font-family: Work Sans;
  font-size: 2.3rem;
  padding: 1rem 0;
  color: #0d0d0d;
}

.form-btn {
  background-color: #df2176;
  color: #fff;
  border: none;
  padding: 8px, 16px;
  border-radius: 4px;
  width: 30%;
  font-size: 1.8rem;
  font-weight: 600;
  height: 5rem;
  text-decoration: none;
  text-align: center;
  margin: 2.5rem;
  justify-content: center;
  align-items: center;
}

.text-label {
  font-family: Work Sans;
  font-size: 2.3rem;
  padding: 1.3rem;
  color: #0d0d0d;
  position: relative;
  bottom: -10px;
  margin-right: 4rem;
}

.select_form {
  font-size: 16px;
  border: 1px solid #aca9a9;
  border-radius: 5px;
  padding: 5px;
  width: 91%;
  padding: 10px 6px;
}

.resume-input {
  font-size: 14px;
  width: 23rem;
}

@media (min-width: 510px) and (max-width: 790px) {

  /* body {
      background-color: yellow;
  } */
  .form_input {
    color: #666;
    font-size: 16px;
    border: 1px solid #aca9a9;
    border-radius: 1rem;
    padding: 6px;
    max-width: 70%;
    margin: 1.5rem;
    height: 39px;
  }

  .form_label {
    font-family: Work Sans;
    font-size: 20px;
    padding: 1.3rem;
    color: #0d0d0d;
  }

  .select_form {
    height: 39px;
    font-size: 14px;
    border: 1px solid #aca9a9;
    border-radius: 5px;
    margin-left: 0rem;
  }

  .resume-input {
    font-size: 14px;
    width: 23rem;
  }

  .text-label {
    margin-right: 0;
    font-size: 20px;
  }

}

@media (max-width: 600px) {

  /* body{
    background-color: #ff0055;
  } */
  strong {
    position: relative;
    bottom: 5px;
  }

  .form_label {
    font-family: Work Sans;
    font-size: 13px;
    padding: 10px;
    color: #0d0d0d;
  }

  .select_form {
    height: 30px;
    font-size: 11px;
    margin-left: 0;
  }

  .form-list {
    display: flex;
    flex-direction: column;
  }

  .form_input {
    color: #666;
    font-size: 16px;
    border: 1px solid #aca9a9;
    border-radius: 5px;
    padding: 6px;
    max-width: 83%;
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 2px;
    height: 39px;
  }

  .form_label {
    font-family: Work Sans;
    ;
    font-size: 20px;
    padding-top: 23px;
    padding-bottom: 0px;
    color: #0d0d0d;
    position: relative;
    right: 8px;
  }

  .resume-input {
    font-size: 10px;
    width: 37rem;
  }

  .select_form {
    height: 39px;
    font-size: 14px;
    border: 1px solid #aca9a9;
    border-radius: 5px;
    margin-left: 0rem;
    width: 91%;
    padding: 10px;
  }

  .select-error {
    margin-top: -13px;
    margin-left: -1px;
  }

  .text-label {
    font-family: Work Sans;
    font-size: 20px;
    padding-top: 23px;
    padding-bottom: 0px;
    color: #0d0d0d;
    position: relative;
    right: 8px;
    top: 2px;
  }

  .form-btn {
    width: 51%;
    font-size: 17px;
  }

  .error-field {
    margin-left: -3px;
    margin-top: 2px;
    color: #ff0055;
  }

  .error-capta {
    margin-top: 4px;
  }


  .capta-field {
    margin-left: 10px;
    margin-top: 24px;
  }
}

.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-row-gap: 20px; */

  /* remove the below max-width once you fix container width  */
  /* max-width: 1500px; */
  width: 100%;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
}

.form_fields {
  color: #666;
  font-size: 16px;
  border: 1px solid #aca9a9;
  padding: 10px 6px;
  width: 91%;
  border-radius: 5px;
  margin: 0px;
  outline: none;
}
.loader-container {
  width: 100%;
  padding: 2rem; /* Adjust padding for smaller screens */
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .loader-container {
    padding: 1rem; /* Adjust padding for smaller screens in mobile view */
  }

  .react-shimmer-effects-wrapper {
    /* Add specific styles to make the shimmer effect responsive */
    max-width: 100%; /* Ensure the shimmer effect doesn't exceed the container width */
    margin: 0 auto; /* Center the shimmer effect within the container */
  }
}
