z:root {
    --primary: #fff;
  }
  
  .btn {
    margin: 6px;
  color: rgb(248, 240, 244);
  background-color:#df2176;
  border-radius: 90px;
  min-width: 200px;
  min-height: 60px;
  font-size: 20px;
  font-weight: 600;
  }

  
  
  .btn--primary {
  background-color:#df2176;
  color: rgb(248, 240, 244);
    border: 1px solid var(--primary);
  }

  .butn {
    margin: 6px;
  color: rgb(248, 240, 244);
  background-color:#df2176;
  border-radius: 90px;
  min-width: 200px;
  min-height: 60px;
  font-size: 20px;
  font-weight: 600;
  }

  
  
  .butn--primary {
  background-color:#64a916;
    color: #242424;
    border: 1px solid var(--primary);
  }

 
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .btn--small {
    padding: 4px 15px;
    font-size: 16px;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover,
  .btn--small:hover {
  background-color: #685de5;
  color: white;
  }
  button:disabled{
    background-color: rgb(144, 135, 141) !important;
    color: #242424 !important;
    font-weight: 600;
  }
  @media screen and (max-width: 500px) {
    .btn--medium:hover {
      background-color: #685de5;
      color: white;
    }
    .btn--small:hover {
      background-color: #685de5;
      color: white;
    }
    
  }
  @media (max-width: 767px) {
    .btn {
      width: 110px;
      height: 36px;

    }
    .btn--medium{ 
      font-size: 10px;
    }
  }
  
  