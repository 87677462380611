.navbar {
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  position: sticky;
  top: 0;
  z-index: 9999;
}


.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  flex-grow: 1;
 
  @media (min-width: 1400px) {
    max-width: 1280px;
  }
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  margin:0
}
.navbar-logo {
  object-fit: contain;
  max-width: 100%;
  max-height: 50px; /* Adjust this value based on your needs */
  width: 100%;
  margin-right: auto;
}
.nav-links {
  color: aliceblue;
  text-decoration: none;
  padding: 30px 20px;
  border-bottom: 5px solid #000
}

.nav-links:hover {
  border-bottom: 5px solid #df2176;
  color: aliceblue;
}

.menu-icon {
  display: none;
}

.fa-times,.fa-bars{
  color: #fff;
  font-size: 2rem;
}

@media screen and (max-width: 1025px) {
  .nav-menu {
    flex-direction: column;
    position: absolute;
    top: 70px;
    background-color: black;
    width: 100%;
    opacity: 0;
    padding-left: 0;
    display: none;
    left: 0;
    @media screen and (max-width:480px) {
      top: 50px;
    }
  }
  .nav-menu.active {
    opacity: 1;
    display: flex;
    transition: all 0.5s ease;
  }

  .navbar-logo {
    @media screen and (max-width:480px) {
      width: 40vw;
    }
    
  }
  .nav-links {
    text-align: center;
    width: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding:20px
  }
  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }
  .menu-icon {
    display: flex;
    font-size: 1.8rem;
    cursor: pointer;
  }

 
}
