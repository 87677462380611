
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@900&family=Work+Sans&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

a {
  text-decoration: none; /* Remove underline */
}


body {
  overflow: hidden;
}


.footer {
  background-color:#213343 !important;
  font-family: 'Arial', sans-serif;
 
  /* border-top: 1px solid #ccc; */
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  /* padding: 20px; */
  /* margin-top: 20px;  */
}




.sb_footer {
  display: flex;
  flex-direction: column;
 max-width:1280px ;
 justify-content: center;
 margin: 0 auto;
}

.footer-link {
  color: inherit;
  transition: color 0.3s ease; 
}

.footer-link:hover {
  color: blue;
}

.sb_footer-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem; 
}

.sb_footer-links-div {
  width: 300px;
  margin: 1rem;
  padding-left: 5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white
}
/* .sb_footer-links-div:hover{
  color: blue;

} */
/* a {
    text-decoration: none;
    color: black;

} */


.sb_footer-links-div h4{
  font-size: 16px;
  line-height: 60px;
  margin-bottom: 0.9rem;  
  font-weight: 550; 
}

.sb_footer-links-div p{
    font-size: 14px;
    line-height: 20px;
    margin: 2px 0;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

@media screen and (max-width:550px){
    .sb_footer-links div{
        margin: 1rem 0;
    }
    .section_padding {
      padding: 0rem 0rem;
    }
    .sb_footer-links-div h4{
      margin-bottom: -1.1rem;
    }
    .sb_footer-links-div p{
      font-size: 14px;
    }
    .sb_footer-links{
      margin-bottom: 0rem;
    }

}

@media (min-width: 820px) and (max-width: 1180px) {
  .sb_footer-links {
      /* background-color: yellow; */
      padding: 40px;
  }
}
