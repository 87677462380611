@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@900&family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

/* A95415 */
.typewriter {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  display: block;
  width: 100%;
  margin-bottom: 5.5%;
  /* min-height: 44vh; */
  min-height: 200px;
}
.previous-image{
  padding: 10px;
}

.portfolio-item {
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center; 
}

/* Default styles for larger screens */
.app-store-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.app-store-icons a {
  display: flex;
  text-decoration: none;
  transition: transform 0.3s ease-in-out;
  width: 200px;
}
.app-store-icons a:nth-child(even){
  margin-left: 20px;
}

.app-store-icons a:hover {
  transform: scale(1.1);
}

.app-store-icons img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Media query for screens up to 768px width */
@media screen and (max-width: 768px) {
  .portfolio-item-content {
    flex-direction: column;
    text-align: center;
  }

  .app-store-icons {
    margin: 10px 0px 50px;
  }

  .app-store-icons a {
    width: 130px;
  }

}



/* Media query for mobile screens (adjust the max-width as needed) */
@media screen and (max-width: 767px) {
  .typewriter {
    font-family: Montserrat;
    font-size: 30px;
    min-height: 21vh;
  }

  p.section-subheading {
    font-size: 15px !important;
    padding: 11px;
    /* margin: 0px 0px 20px; */
  }
}

* {
  box-sizing: border-box;
  font-smooth: inherit;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

body {
  font-family: Work Sans;
  font-weight: 400;
  font-size: 16px;
}

.btn-xl {
  padding: 1.25rem 2.5rem;

  font-size: 1.125rem;
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.btn-primary {
  color: #fff;
  background-color: #ffc800;
  border-color: #ffc800;
}

.btn {
  margin: 20px;
  color: rgb(248, 240, 244);
  background-color: rgb(188, 43, 123);
  border-radius: 90px;
  width: 160px;
  font-size: 13px;
  padding: 15px 33px;
  font-weight: bold;
}

.btn {
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fa-play-circle {
  margin-left: 4px;
}

.masthead {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  color: #fff;
  /* background-image: url("/public/images/lap.jpg"); */
  /* background-image: url("/public/images/5103784.jpg"); */
  background-color: #000;

  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

.masthead::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); */
  z-index: 1;
}


.masthead * {
  position: relative;
  z-index: 2;
}

/* .background-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1; 
} */



header.masthead .masthead-subheading {
  font-size: 24px;
  font-weight: 700;
  /* line-height: 3.25rem; */
  line-height: 1.5;

  margin-bottom: 2rem;
  color: #fff;

  /* line-height: 3.25rem;
  margin-bottom: 2rem; */
}

header.masthead .masthead-heading {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #fff;
  letter-spacing: .5px;
}

/* font-style: italic; */
/* line-height: 1.5rem;
  margin-bottom: 25px; */


/* New Tech */

/* //New projects */
.tech_container {
  /* background-color: #f5f6f7; */
  background: linear-gradient(to bottom, #adb5bd, #ffffff);
}

.cards_tech {
  height: auto;
  padding: 40px;
  text-align: center;
  width: 100%;
  
}

.cards_tech h2 {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  padding-bottom: 10px;
}

.cards_tech h4 {
  text-align: center;
  color: #595959;
  font-size: 18px;
  padding-bottom: 35px;
}

.container_project {
  background-color: #df2176;
  height: auto;
  margin-top: 2%;
  padding: 100px 0px 100px 0px;
  text-align: center;
  width: 100%;
}

.nextProject {
  color: white;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
}

.pr-btns {
  margin-top: 32px;
}

.pr-btns .btn {
  margin: 6px;
  /* color: rgb(188, 43, 123); */
  color: #df2176;
  background-color: rgb(241, 232, 237);
  border-radius: 90px;
  width: 200px;
  display: inline-block;
}


.btn {
  margin: 6px;
  color: rgb(248, 240, 244);
  background-color: rgb(188, 43, 123);
  border-radius: 90px;
  width: 180px;
  font-size: 20px;
}

/* Our services */

.multiCaro {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 1%;
  width: 100%;
  color: #3420d0;
}

/*preetibsssssssssssssssssssssssssssssssssssss*/

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: black;
}

/* .multiCaro h3 {
  color: #601eb6;
  padding-top: 0%;
} */

.cards_serv {
  box-sizing: border-box;
  padding: 30px 0;
  background: linear-gradient(to bottom, #adb5bd, #ffffff);

}

.cards_serv h2 {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.cards_serv h4 {
  text-align: center;
  color: #595959;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 10px;
}

.cards_serv img {
  z-index: 5;
}

.cards_serve_img {
  z-index: -999;
  overflow: hidden;
}

.caro_img {
  /* background-color: #df2176; */
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .caro_img {
    /* background-color: #3420d0; */
    max-width: 60%;
    max-height: 60%;
  }

  .cards_serv {
    padding-top: 20px;
    box-sizing: border-box;
  }
}

/* //Clients */

.cards_client {
  height: auto;
  padding-bottom: 10%;
  padding-top: 10%;
  text-align: center;
  width: 100%;
}

.cards_client h2 {
  font-size: 32px;
  padding-bottom: 5px;
  font-weight: 500;
}

.cards_client h4 {
  text-align: center;
  color: #595959;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 15px;
}

.our_clients img {
  border-radius: 20px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #d1d1d1;
  z-index: 5;
}

@media screen and (min-width: 600px) {
  .cards_client {
    height: auto;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-items: center;
    padding-top: 30px;
    padding-bottom: 35px;

  }
}

.productSlide {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
  bottom: 10px;

  /* background-color: burlywood; */
}

/* //Our Services */

/* .page-section-services {
  padding: 60px 0px 100px 0px;
} */

p.section-subheading {
  font-size: 18px;
  /* margin: 0px 0px 20px; */
}

.sample-slider [class^="swiper-button-"]::after {
  content: "";
}

/*adjust arrow size*/
.sample-slider [class^="swiper-button-"] {
  width: 55px;
  height: 55px;
}

/*adjust arrow position*/
.sample-slider .swiper-button-next {
  right: -5px;
}

.sample-slider .swiper-button-prev {
  left: -5px;
}

@media screen and (min-width: 768px) {
  header.masthead {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  header.masthead .masthead-subheading {
    font-size: 100px;
    font-weight: 700;
    line-height: 10rem;
    color: #fff;
    margin-bottom: 2rem;

    /* margin-bottom: 4rem; */
  }

  header.masthead .masthead-heading {
    font-size: 3rem;
    margin: 0px 0px 20px;
    width: 100%;
    color: #fff;
    padding-bottom: 3rem;
    line-height: 4.25rem;
    font-weight: 500;
    font-family: Roboto;

    /* padding-bottom: 40px; */
    /* font-style: italic; */
    /* line-height: 2.25rem; */
    /* margin-bottom: 2rem; */
  }
}

@media screen and (max-width: 768px) {
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .nextProject {
    font-size: 20px;
    margin: 1rem;

  }
  .pr-btns .btn{
font-size: 18px;
  }
}

/* Clients Testimols */

.client_cont div .img img {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}

/* Testimonial */

.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}

.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}

.testimonial .slick-arrow.slick-next {
  right: -70px;
}

.testimonial .slick-arrow.slick-prev {
  left: -70px;
}

.testimonial .slick-dots li button:before {
  font-size: 12px;
}

.testimonial .slick-dots li {
  top: -30px;
}

/* client */

.client_h1 {
  font-size: 3.5rem;
  padding-bottom: 10px;
  font-weight: 600;
}

/* technology */
.swiper-slide-img {
  margin-bottom: 10rem;
}

.swiper-button-prev {
  color: rgb(20, 19, 19) !important;
  font-weight: 800;
  font-size: small;
  position: relative;
  left:-3px
}

.swiper-button-next {
  color: #000 !important;
  font-weight: 800;
}

/* loading section */
/* .loadingSpinnerContainer{
  text-align: center;
  margin: 0 auto;  
  } */

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(12, 12, 12, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  /* border-color: #0b0c0c transparent #0b0c0c transparent; */
  /* border-color: #d72c2c transparent #d72c2c transparent; */
  color: black; /* Text color */
  border-color: white transparent white transparent; /* Border color */
  border-radius: 50%;
  animation: spin 1.2s linear infinite;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-message {
  font-size: 2.5rem;
  margin-top: 0 auto;
  padding-left: 10px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* color: #d72c2c; */
  color:white; /* Black text color */
  z-index: 99999;
  font-weight: 900;
}

/* /dialoge */
/* @media screen and (max-width: 600px) {
  .pr-btns .btn:hover{
    background-color: #685de5;
    cursor: pointer !important;
  }
  
}
 */

@media (max-width: 768px) {
  .arrow-button {
    display: none;
  }
}